// extracted by mini-css-extract-plugin
export var article__meta = "single-module--article__meta--2B_eP";
export var article__tax = "single-module--article__tax--JP9-I";
export var article = "single-module--article--1iUKO";
export var featimg = "single-module--featimg--94Ix-";
export var homeSection = "single-module--homeSection--1gnDK";
export var article__title = "single-module--article__title--1m8hL";
export var article__content = "single-module--article__content--7dEpL";
export var articlelist = "single-module--articlelist--2cPUw";
export var listitem = "single-module--listitem--29tzQ";
export var container = "single-module--container--zCgXc";
export var dark__overlay = "single-module--dark__overlay--1hqEu";
export var hero__container = "single-module--hero__container--JBbLh";
export var video = "single-module--video--2QPCm";
export var hero__text = "single-module--hero__text--DQpDG";
export var crest = "single-module--crest--2DDnN";
export var crestContainer = "single-module--crestContainer--yFUjS";
export var container__top = "single-module--container__top--NT6KQ";
export var centered = "single-module--centered--2R_6J";
export var center__text = "single-module--center__text--2mS43";
export var belowheader = "single-module--belowheader--1kv9l";
export var crestcontainer = "single-module--crestcontainer--3hLdg";